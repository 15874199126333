import {Controller} from "stimulus";

export default class extends Controller {
  static values = {autoSubmit: Boolean}
  static targets = ['masterInput', 'slaveInput'];

  toggleState(e) {
    let isChecked = $(e.currentTarget).prop('checked');
    let targetInputsClass = $(e.currentTarget).data('target-inputs');
    let targetInputs = $(targetInputsClass);
    if(isChecked) {
      targetInputs.prop('checked', isChecked);
    }
    if(this.autoSubmitValue) {
      $(e.currentTarget).closest('form').find('input[type="submit"]').trigger('click');
    }
  }

  manageMasterInput() {
    let targetInputsClass = $(this.masterInputTarget).data('target-inputs');
    let allChecked = $(this.slaveInputTargets).length === $(targetInputsClass + ':checked').length;
    $(this.masterInputTarget).prop('checked', allChecked);
  }

}
